import { ReactElement } from "react"
import Header from "./Header"
import { ChevronDownIcon } from "@heroicons/react/24/outline"

type Item = {
  columnOne: string | ReactElement
  columnTwo: string | ReactElement
}


type BoxListProps = {
  title: string
  titleSize?: 'medium' | 'large' | 'xtra-large'
  list: Item[]
}

const BoxList = ({ title, titleSize, list }: BoxListProps) => {
  return (
    <div>
      <Header size={titleSize} className="pb-3">{title}</Header>
      <div className="flex flex-col border p-4 rounded gap-4">
        {(!list || list.length === 0) && <div className="text-sm">No upcoming events at this time</div>}
        {list && list.length > 0 && list.map((item, index) => {
          return (
            <div key={index} className="grid grid-cols-4 justify-between gap-2">
              <Header className="col-span-3" size="small">{item.columnOne}</Header>
              <div className="text-sm col-span-1">{item.columnTwo}</div>
            </div>
          )
        })}
        {list.length === 5 && (
          <div className="flex items-center gap-1 cursor-pointer text-feather text-sm">
            See more
            <ChevronDownIcon className="text-feather size-4"/>
          </div>
        )}
      </div>
    </div>
  )
}

export default BoxList