import { useNavigate } from "react-router-dom"
import Header from "./Header"
import FormattedText from "./FormattedText"
import { useEffect, useState } from "react"
import { fetchCompanyUpdates } from "../api"
import { cn, formatDate } from "../utils"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import ExternalLink from "./ExternalLink"

const formatCategory = (category: any) => {
  switch(category) {
    case 'earnings_release': {
      return 'Earnings Release'
    }
    case 'press_release': {
      return 'Press Release'
    }
    case '_10k': {
      return '10-K Filing'
    }
    case '_10q': {
      return '10-Q Filing'
    }
    case 'investor_deck': {
      return 'Investor Day Deck'
    }
    case 'earnings_call': {
      return 'Earnings Call'
    }
    case 'fact_sheet': {
      return 'Fact Sheet'
    }
    case 'ars': {
      return 'ARS'
    }
    case 'other': {
      return 'Other'
    }
    case 'investor': {
      return 'Investor Update'
    }
    case 'investor_news': {
      return 'Investor News'
    }
    case 'conference': {
      return 'Conference'
    }
    case 'webinar': {
      return 'Webinar'
    }
  }
}

const ProductUpdates = ({ className, company }: { className: string, company: any }) => {
  const navigate = useNavigate()
  const [companyUpdates, setCompanyUpdates] = useState<any[]>([])

  useEffect(() => {
    fetchCompanyUpdates(navigate, company.id).then((data: any) => {
      setCompanyUpdates(data.data)
    })
  }, [])

  return (
    <div className={cn("border p-6 rounded", className)}>
      <Header className="pb-3">Latest relevant updates</Header>
      <div className="flex flex-col gap-3">
        {companyUpdates.length === 0 && <div className="text-sm">No new updates at this time</div>}
        {companyUpdates.map((companyUpdate: any) => {
          return (
            <div>
              <Header className="flex items-center gap-1" size="medium">
                <>
                  {companyUpdate.updatable.title}
                  <ExternalLink url={companyUpdate.updatable.url}>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 stroke-ruby"/>
                  </ExternalLink>
                </>
              </Header>
              <div className="text-sm py-1 text-feather">
                {formatCategory(companyUpdate.updatable.type)}
                {companyUpdate.updatable.date && `, ${formatDate(companyUpdate.updatable.date)}`}
                </div>
              <div className="text-sm"><FormattedText className={"line-clamp-4"} text={companyUpdate.updatable.description}></FormattedText></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProductUpdates