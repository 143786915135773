import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import { useEffect, useState } from "react";
import { fetchCompany } from "../../api";
import isNil from "lodash/isNil";
import { SkeletonLoader } from "../SkeletonLoader";

const Sidebar = ({ classNames }: { classNames?: string}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [company, setCompany] = useState<any | null>(null)

  const { id } = useParams();

  useEffect(() => {
    if (isNil(id)) {
      return
    }

    fetchCompany(navigate, id).then(data => setCompany(data))
  }, [id])

  const pages = [
    {
      name: "Overview",
      url: ``

    },
    {
      name: "Metrics",
      url: `/metrics`
    },
    {
      name: "Documents & Artifacts",
      url: `/documents`
    },
    {
      name: "Recent News",
      url: `/news`
    }
  ]

  let activeTab = pages.map(page => page.url).indexOf(location.pathname.slice(location.pathname.lastIndexOf("/")))
  activeTab = activeTab != -1 ? activeTab : 0
  
  return (
    <div className={`min-w-[215px] py-10 fixed ${classNames}`}>
      <>
        <Header size={"large"} className="pl-1 pb-3">{company ? company.name : <SkeletonLoader classNames={"h-3 w-28 my-2"}/>}</Header>
        <div>
          {
            pages.map((page: any, index: number) => {
              const innerTabStyle = activeTab === index
                ? "text-ruby font-bold border-b-ruby bg-gradient-to-t from-lightRuby to-faintOrange"
                : "border-b-slate-300"

              return (
                <div
                  className={`border-b ${innerTabStyle} pt-2 px-1 cursor-pointer`}
                  onClick={() => {
                    navigate(`/companies/${id}${page.url}`)
                  }}
                >
                  {page.name}
                </div>
              )
            })
          }
        </div>
      </>
    </div>
  )
}

export default Sidebar