import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchCompany } from "../../api"
import ProductUpdates from "../../components/ProductUpdates"
import Events from "../../components/Events"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import isNil from "lodash/isNil"
import SectionsDisplay from "../../components/Company/Metrics/SectionsDisplay"
import { SkeletonLoader } from "../../components/SkeletonLoader"

const Company = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  const [company, setCompany] = useState<any | null>(null)

  useEffect(() => {
    if (isNil(id)) {
      return
    }

    fetchCompany(navigate, id).then((result) => setCompany(result))
  }, [])

  const METRIC_SECTIONS = ['company']

  return (
    <CompanyPageContainer>
      <div className="flex flex-col pb-20 w-full h-full">
        <div className="p-10 w-full h-full">
          {!company && <SkeletonLoader classNames={"w-full h-5"}/>}
          {company && (
            <>
              <div className="font-bold text-3xl pb-6">Overview - {company.name}</div>
              <div className="flex flex-col gap-6">
                <div className="grid grid-cols-3 grid-flow-rows gap-6 w-full">
                  <ProductUpdates className="col-span-2" company={company} />
                  <Events company={company}/>
                </div>
                <SectionsDisplay company={company} divisionTypes={METRIC_SECTIONS} />
              </div>
            </>
          )}
        </div>
      </div>
    </CompanyPageContainer>
  )
}

export default Company