import { useEffect, useState } from "react"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import FormattedText from "../../components/FormattedText"
import Header from "../../components/Header"
import { ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import ExternalLink from "../../components/ExternalLink"
import { useNavigate, useParams } from "react-router-dom"
import { fetchDocuments } from "../../api"
import isNil from "lodash/isNil"

const docType = (docType: string) => {
  switch(docType) {
    case 'press_release': {
      return 'Press Release'
    }
    case 'earnings_release': {
      return 'Earnings Release'
    }
    case 'earnings_call': {
      return 'Earnings Call'
    }
    case '_10k': {
      return '10-K'
    }
    case '_10q': {
      return '10-Q'
    }
    case 'investor_deck': {
      return 'Investor Day Deck'
    }
    case 'fact_sheet': {
      return 'Fact Sheet'
    }
    case 'ars': {
      return 'Annual Report'
    }
    default: {
      return 'Other'
    }
  }
}

export const formatDocName = (document: any) => {
  const formattedType = docType(document.type)

  return (
    <div>
      {formattedType} {document.quarter}'{document.year.toString().slice(2)}
    </div>
  )
}
type DocumentProps = {
  document: any
}

const groupDocs = (documents: any[]) => {
  return documents.reduce((previous, currentDoc, _index) => {
    switch (currentDoc.quarter) {
      case 'Q1': {
        /* @ts-ignore */
        previous.q1.push(currentDoc)
        return previous
      }
      case 'Q2': {
        /* @ts-ignore */
        previous.q2.push(currentDoc)
        return previous
      }
      case 'Q3': {
        /* @ts-ignore */
        previous.q3.push(currentDoc)
        return previous
      }
      case 'Q4': {
        /* @ts-ignore */
        previous.q4.push(currentDoc)
        return previous
      }
      case 'FY': {
        /* @ts-ignore */
        previous.fy.push(currentDoc)
        return previous
      }
    }

    return previous
  }, { q1: [], q2: [], q3: [], q4: [], fy: []})
}

const Document = ({ document }: DocumentProps) => {
  const [expanded, setExpanded] = useState(false)

  console.log(document.quotes)
  return (
    <div key={document.id} className="flex flex-row items-center gap-1">
        <div className="pt-4 pb-2">
          <Header>
            <div className="flex gap-2 items-center">
              {formatDocName(document)}
              <ExternalLink url={document.url}>
                <ArrowTopRightOnSquareIcon className="stroke-ruby size-4" />
              </ExternalLink>
            </div>
          </Header>
          {document.summary && (
            <>
              <div className={expanded ? "" : "line-clamp-3"}>
                <Header className="pt-3" size="small">Summary</Header>
                <FormattedText text={document.summary} />
                {document.quotes.length > 0 && <Header className="pt-3" size="small">Notable quotes</Header>}
                {document.quotes.map((quote: any, index: number) => {
                  return (
                    <>
                      <FormattedText key={index} text={quote} />
                    </>
                  )
                })}
              </div>
              {!expanded && (
                <div className="flex items-center gap-1 cursor-pointer text-feather text-sm pt-2" onClick={() => setExpanded(true)}>
                  See more
                  <ChevronDownIcon className="text-feather size-4"/>
                </div>
              )}
              {expanded && (
                <div className="flex items-center gap-1 cursor-pointer text-feather text-sm pt-2" onClick={() => setExpanded(false)}>
                  See less
                  <ChevronUpIcon className="text-feather size-4"/>
                </div>
              )}
            </>
          )}
      </div>
    </div>
  )
}

type DocumentListProps = {
  title: string
  documents: any[]
}

const DocumentList = ({ title, documents }: DocumentListProps) => {
  if (documents.length === 0) {
    return <></>
  }

  return (
    <>
      <div className="border-b">
        <Header className="pb-2 pt-4" size="medium">{title}</Header>
      </div>
      {documents.map((document: any) => {
        return <Document document={document} />
      })}
    </>
  )
}

const Documents = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [documents, setDocuments] = useState<any>([])

  useEffect(() => {
    if (isNil(id)) {
      return
    }

    fetchDocuments(navigate, id).then(documents => {
      setDocuments(documents)
    })
  }, [id])

  const latestYear = documents.map((document: any) => document.year).sort((a: string , b: string) => -a.localeCompare(b))[0]
  const visibleYears = new Array(5).fill(0).map((_el, index) => {
    return latestYear - index
  })

  return (
    <CompanyPageContainer>
      <div className="p-10 w-full">
          <div className="font-bold text-3xl pb-6">Documents & Artifacts</div>
          <div className="flex flex-col">
            {visibleYears.map((year: number, index: number) => {
              const [expanded, setExpanded] = useState(false)

              if (isNil(latestYear)) {
                return <></>
              }

              const relevantDocs =  documents.filter((document: any) => document.year.localeCompare(new String(year)) === 0)
              const groupedDocs = groupDocs(relevantDocs)

              return (
                <div key={`${year}-${index}`} className="py-6 border-b border-b-feather">
                  <Header size="xtra-large">
                    <div className="flex flex-row items-center">
                      <div className="min-w-14">{year}</div>
                      {!expanded && <div className="h-3 w-3 cursor-pointer border-l-slate-700 border-l-[12px] border-y-transparent border-y-[6px]" onClick={() => setExpanded(!expanded)}></div>}
                      {expanded && <div className="h-3 w-3 cursor-pointer border-t-slate-700 border-t-[12px] border-x-transparent border-x-[6px]" onClick={() => setExpanded(!expanded)}></div>}
                    </div>
                  </Header>
                  <div>
                    {expanded && (
                      <div>
                        <DocumentList title="FY" documents={groupedDocs.fy} />
                        <DocumentList title="Q4" documents={groupedDocs.q4} />
                        <DocumentList title="Q3" documents={groupedDocs.q3} />
                        <DocumentList title="Q2" documents={groupedDocs.q2} />
                        <DocumentList title="Q1" documents={groupedDocs.q1} />
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
      </div>
    </CompanyPageContainer>
  )
}

export default Documents