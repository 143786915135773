// import { useParams } from "react-router-dom"
import { useParams } from "react-router-dom"
import { cn } from "../../../utils"
import { ColumnTable } from "../../ColumnTable"
import FormattedText from "../../FormattedText"
import Header from "../../Header"
import MetricSourceTooltip, { sourceTooltipAnchor } from "../../MetricSourceTooltip"
import { MathJax } from "better-react-mathjax"
import { Tooltip } from "react-tooltip"
import startCase from "lodash/startCase"

type SectionsDisplayProps = {
  company: any
  divisionTypes: string[]
}

const transformQuarterYearToHeader = (quarterYear: QuarterYear) => {
  return `${quarterYear.quarter}'${new String(quarterYear.year).slice(2)}`
}

const generateRowConfigs = (division_name: string, metrics: any) => {
  const { id } = useParams()

  return metrics.map((metric: any) => {
    const boldClassNames = metric.styles.includes('bold') ? 'font-bold': ''
    const italicClassNames = metric.styles.includes('italic') ? 'italic': ''
    const alignmentClassNames = metric.styles.includes('right_aligned') ? 'justify-end text-end': ' justify-start text-start'

    return {
      title: metric.title,
      titleClassNames: cn(
        `flex max-w-[300px]`,
        boldClassNames, 
        italicClassNames,
        alignmentClassNames,
      ),
      metricClassNames: cn(boldClassNames),
      customRender: (row: any, rowIndex: number, columnIndex: number) => {
        const classNames = row.derived ? 'text-brightPurple' : ''
        const anchor = sourceTooltipAnchor(division_name, row, rowIndex, columnIndex)
        let showCalc = false
        let revenue = '0'
        let customer_count = '0'
        let metrics: any[] = []

        if (row.derived && ['$17,456.9', '$18,012.2', '$16,664.9', '$16,310.2'].includes(row.value)) {
          showCalc = true
          revenue = row.value === '$17,456.9' ? '$19,202.6' : row.value === '$18,012.2' ? '$18,012.2' : row.value === '$16,664.9' ? '$16,498.3' : '$15,005.4'
          customer_count =row.value === '$17,456.9' ? '1,100,000' : row.value === '$18,012.2' ? '1,000,000' : row.value === '$16,664.9' ? '990,000' : '920,000'

          metrics = [{
            label: 'Revenue (M)',
            value: revenue,
            segment: 'Overall'
          },
          {
            label: 'Customer count',
            value: customer_count,
            segment: 'Overall'
          }]
    
        }

        return (
          <>
            <div id={anchor} className={cn('cursor-pointer', classNames)}>{row.value}</div>
            {!showCalc && (<MetricSourceTooltip id={id || ''} row={row} anchorSelector={anchor} />)}
            {showCalc && (
              <Tooltip
                anchorSelect={`#${anchor}`}
                variant="light"
                className="border !opacity-100"
                classNameArrow="border-b border-r"
                clickable
                openOnClick
                closeEvents={{ click: true }}
              >
                <div className="flex flex-col justify-between w-[400px] my-3">
                  <div className="flex flex-col justify-start text-start">
                    {metrics.map((metric: any) => {
                      return (
                        <div className="flex flex-col">
                            <div className="flex flex-row justify-between text-base">
                              <div className="font-bold">{metric.label}</div>
                              <div className="font-medium">{metric.value}</div>
                          </div>
                          <div className="text-feather text-xs pb-1">{startCase(metric.segment)}</div>

                        </div>
                      )
                    })}
                  </div>
                  <MathJax className="pt-2">{`\\(\\LARGE{\\frac{${revenue} \\times 10^{6}}{${customer_count}}}\\)`}</MathJax>
                </div>
              </Tooltip>
            )}
          </>
        )
      }
    }
  })
}

type DivisionDisplayProps = {
  division: any
}

const productSize = (division: any) => {
  switch(division.type) {
    case 'company': {
      return 'xtra-large'
    }
    case 'segment': {
      return 'xtra-large'
    }
    case 'subsegment': {
      return 'large'
    }
    case 'product': {
      return 'medium'
    }
  }
}

type QuarterYear = {
  quarter: string
  year: string
}

const QUARTER_ORDER_VALUE = {
  'Q1': 1,
  'Q2': 2,
  'Q3': 3,
  'Q4': 4,
  'FY': 5
}

const DivisionDisplay = ({ division }: DivisionDisplayProps) => {
  const quarterYears: Record<string, QuarterYear> = {}
  division.metrics.forEach((metric: any) => {
    metric.metric_values.forEach((metricValue: any) => {
      if (quarterYears[`${metricValue.year}${metricValue.quarter}`]) {
        return
      }

      quarterYears[`${metricValue.year}${metricValue.quarter}`] = { quarter: metricValue.quarter, year: metricValue.year}
    })
  })

  const sortedQuarterYears = Object.values(quarterYears).sort((a: QuarterYear, b: QuarterYear) => {
    if (a.year === b.year) {
      /* @ts-ignore */
      return QUARTER_ORDER_VALUE[a.quarter] - QUARTER_ORDER_VALUE[b.quarter]
    }

    return a.year.localeCompare(b.year)
  })

  const columnHeaders = sortedQuarterYears.map((quarterYear) => transformQuarterYearToHeader(quarterYear))

  const data = division.metrics.map((metric: any) => {
    return sortedQuarterYears.map((quarterYear) => {
      const metricValue = metric.metric_values.find((metricValue: any) => {
        return metricValue.year === quarterYear.year && metricValue.quarter === quarterYear.quarter
      })

      return metricValue
    }) 
  })

  const rowConfigs = generateRowConfigs(division.name, division.metrics)

  return (
    <div>
      <Header className="pb-2" size={productSize(division)}>{division.name}</Header>
      {division.description && (<div className="pb-2 text-sm">{division.description}</div>)}
      {data.length > 0 && (
        <ColumnTable
          rowConfigs={rowConfigs}
          data={data}
          columnHeaders={columnHeaders}
          clickDisabled={(row) => row.status !== 'completed'}
          tableClassNames="rounded-md"
        />
      )}
      {division.qualifier && (<FormattedText className="pt-3 text-xs" text={division.qualifier}></FormattedText>)}
    </div>
  )
}

const SectionsDisplay = ({company, divisionTypes }: SectionsDisplayProps) => {
  const divisions = company.divisions.filter((division: any) => divisionTypes.includes(division.type))

  return (
    <div className="flex flex-col gap-6 pt-6" >
      {divisions.map((division: any) => {
        return (
          <DivisionDisplay division={division} />
        )
      })}
    </div>
  )
}

export default SectionsDisplay