import { useEffect, useState } from "react"
import { fetchCompany } from "../../api"
import { useNavigate, useParams } from "react-router-dom"
import isNil from "lodash/isNil"
import { Tabs } from "../../components/Tabs"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import MetricsOverview from "../../components/Company/Metrics/Overview"
import FinancialSummary from "../../components/Company/Metrics/FinancialSummary"

export const YEARS = ['2021', '2022', '2023', '2024']
// export const YEARS = [ '2024', '2023', '2022', '2021']

export const lastXYears = (latestYear: string, lookback: number) => {
  return new Array(lookback).fill(0).map((_el, index) => {
    return String(Number(latestYear) - index)
  })
}

const Metrics = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [company, setCompany] = useState<any | null>(null)

  useEffect(() => {
    if (isNil(id)) {
      return
    } 
    
    fetchCompany(navigate, id).then((result) => setCompany(result))
  }, [])

  const tabs = [{
    title: 'Metrics Overview',
    tabContent: (<MetricsOverview company={company} />)
  },{
    title: 'Financial Summary',
    tabContent: <FinancialSummary company={company}></FinancialSummary>
  }
  ]

  return (
    <CompanyPageContainer>
      <div className="p-10 w-full">
          <div className="font-bold text-3xl pb-6">Metrics</div>
          {company && <Tabs tabs={tabs} />}
      </div>
    </CompanyPageContainer>
  )
}

export default Metrics